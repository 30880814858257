class LifeVineBookings {
    private lv: LifeVine;
    private basePath: string;
    private _assignments: LifeVineAssignments;

    constructor(lv: LifeVine) {
        this.lv = lv;
        this._assignments = new LifeVineAssignments(this.lv);
    }

    public pass(path: string) {
        this.basePath = path + '/bookings';
        return this;
    }

    public assignments(id: number) {
        return this._assignments.pass(this.getPath(id));
    }

    public availableSitters(id?: number|null, options?: LifeVineAvailableSittersOptions) {
        options = options || {};
        return this.lv.ajax('GET', this.getPath(id) + '/available-sitters', options);
    }

    public get(id?: number, options?: LifeVineBookingsOptions) {
        let promise;
        if (options) {
            promise = this.lv.ajax('GET', this.getPath(id), options);
        } else {
            promise = this.lv.ajax('GET', this.getPath(id));
        }
        return promise;
    }

    public create(data) {
        return this.lv.ajax('POST', this.getPath(), data);
    }

    public save(id: number, data) {
        return this.lv.ajax('PUT', this.getPath(id), data);
    }

    public remove(id: number) {
        return this.lv.ajax('DELETE', this.getPath(id));
    }

    public quote(data) {
        return this.lv.ajax('GET', this.getPath() + '/quote', data);
    }

    private getPath(id?: number|null) {
        return id ? `${this.basePath}/${id}` : this.basePath;
    }
}

interface LifeVineBookingsOptions {
    status?: number[];
}

interface LifeVineAvailableSittersOptions {
    start_time?: string;
    end_time?: string;
}